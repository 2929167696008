import { userReducer } from "./reducer/user";
import { communityReducer } from "./reducer/community";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

const allReducers = combineReducers({
    user: userReducer,
    community: communityReducer
});

export const store = configureStore({reducer: allReducers})
