let initialState = {
    currentCommunity: "",
    allCommunities: []
}


export const communityReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_COMMUNITY':
            // set the current community to the value given in the payload but keep the allCommunities as is
            return { ...state, currentCommunity: action.payload };
        case 'SET_ALL_COMMUNITIES':
            // set the allCommunities to the value given in the payload but keep the currentCommunity as is
            return { ...state, allCommunities: action.payload };
        case 'LOGOUT':
            return { ...state, allCommunities: [], currentCommunity: ""}
        default:
            return state;
    }
};
