import { store } from '../redux/store'
import { post_request } from './request';

export const setUserCommunity = (async (user) => {
    store.dispatch({ type: "SET_USER", payload: {accessToken: user.accessToken, uid: user.uid, email: user.email, name: user.displayName}});

    let res = await post_request('/community/get_communities_by_user', { user_id: user.uid })
        store.dispatch({
            type: 'SET_ALL_COMMUNITIES', payload: res.data
        })
        store.dispatch({
            type: 'SET_CURRENT_COMMUNITY', payload: res.data[0]
            })
})