import axios from 'axios';
import { store } from '../redux/store'

export const post_request = async (url, body) => {
    let token = store.getState().user.accessToken
    let communityId = store.getState().community.currentCommunity.id

    let base_url = process.env.REACT_APP_ANALYTICS_API_ADDRESS
    url = base_url + url
    let res = await axios.post(
        url,
        body,
        {
            headers: {
                'Authorization': token,
                'Community': communityId
            }
        }
    )

    return res;
}


