// assets
//import { IconKey } from '@tabler/icons';

// constant
/*const icons = {
  IconKey
};*/

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'communities',
  title: 'New Features',
  caption: 'Coming Soon!',
  type: 'group',
  /*children: [
    {
      id: 'authentication',
      title: 'Manage Communities',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'login3',
          title: 'Login',
          type: 'item',
          url: '/login',
          target: true
        },
        {
          id: 'register3',
          title: 'Register',
          type: 'item',
          url: '/register',
          target: true
        }
      ]
    }
  ]*/
};

export default pages;
