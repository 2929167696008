import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import { useNavigate } from 'react-router-dom';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

import { useState } from 'react';

import { setUserCommunity } from 'utils/setUserCommunity';

// ==============================|| APP ||============================== //

const App = () => {
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const [loading, setLoading] = useState(true)

  
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await setUserCommunity(user)
        navigate('/dashboard/default');
        setLoading(false)
      } else {
        if (window.location.pathname !== '/login' || window.location.pathname !== '/register') {
          navigate('/login');
          setLoading(false)
        }
      }
    });
  }, []);
  if (!loading) 
    return (
      <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
      </StyledEngineProvider>
    );
}

export default App;

